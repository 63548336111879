import {
	createApp
} from 'vue'
import App from './App.vue'
// 路由文件
import router from './router/index'
// vuex  导入store
import store from './store/index'
// element ui--pc
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// swiper
import 'swiper/css/swiper.css'
// vant ui--wap
import Vant from 'vant';
import 'vant/lib/index.css';

// swiper
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.css";

import PopMessage from "./components/popMessage.vue"

import Axios from 'axios'
const app = createApp(App)
/* 挂载全局对象 start */
app.config.globalProperties.$http = Axios
// Axios.defaults.baseURL = '/api'

import Web3 from 'web3'
// app.config.globalProperties.Web3 = Web3

// window.Web3 = Web3

// import DApp from './index.js'
// Vue.prototype.$DApp = DApp;
// import Web3 from 'web3-init';
// import VueWeb3 from 'vue-web3';
/* 挂载全局对象 end */
// app.use(VueWeb3, { web3: new Web3(web3.currentProvider) })
app.use(router)
app.use(store)
app.use(ElementPlus)
app.use(Vant)
app.use(VueAwesomeSwiper)
app.component('popMessage',PopMessage)
// app.use(Web3)
app.mount('#app')