<template>

  <transition
      :duration="500"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
  >
    <div class="popMessage" v-show="show" style="z-index: 10000;">
        <div class="popMessageMain">
            <div class="popTitle">
                {{popTitle}}
                <img @click="close" src="@/assets/img/sign/close.png"/>
            </div>
            <div class="popBody">
                <img class="logo" src="../assets/img/logo.png" />
                <div class="popMsg">{{msg}}</div>
                <img class="waiting" src="../assets/img/waiting.png"/>
                <div class="popBottom">{{popBottom}}</div>
            </div>
        </div>
    </div>
  </transition>
</template>

<script>

    // import 'animate.css';

    export default {
        name: "popMessage",
        data() {
            return{
                show: false
            }
        },
        props: {
            popTitle: {
                type: String,
                default: ''
            },
            msg: {
                type: String,
                default: ''
            },
            popBottom: {
                type: String,
                default: ''
            },
        },
        methods: {
            open(){
                this.show = true;
            },
            close(){
                this.show = false;
            }
        },
        mounted() {
        },
    }
</script>

<style scoped>
    .popMessage{
        position: fixed;
        width: 100%;
        height: 100%;
        top:0%;
        left: 0;
        background-color: rgba(0,0,0,0.7);
    }
    .popMessageMain{
        margin: 10% auto;
        width: 30%;
        border-radius: 15px;
    }
    .popTitle{
        position: relative;
        color: white;
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 5rem;
        background-color: #083f58;
        border-radius: 12px 12px 0 0;
        padding-left: 2rem;
    }
    .popTitle img{
        width: 2rem;
        height: 2rem;
        position: absolute;
        top:1.5rem;
        right: 2rem;
    }
    .popBody{
        padding: 2rem;
        background-color: #042439;
        border-radius: 0 0 12px 12px;
    }
    .logo{
        width: 30%;
        margin: 1rem 35%;
    }
    .popMsg{
        text-align: center;
        color: white;
    }
    .waiting{
        width: 40%;
        margin: 3rem 30% 1rem 30%;
    }
    .popBottom{
        text-align: center;
        color: white;
    }
</style>