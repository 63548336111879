<template>
	<router-view></router-view>
	
</template>

<script>
	import {onMounted,onBeforeMount,ref,reactive,onBeforeUnmount} from 'vue'
	import {apigit} from '@/api/api.js'
	import {getAccount,createWeb3,getBalance} from '@/web3/api/common'
	import {symbol,balanceOf,name,decimals,totalSupply,allowance,approve} from '@/web3/api/shield'
	import * as agent from '@/web3/api/agent'
	import * as nft from '@/web3/api/nft'
	import * as user from '@/web3/api/user'
	import {createCase} from '@/web3/api/case'
	
	
	export default {
		name: 'App',
		
		
		setup(){
			// const sheldSwaps = reactive({
			// 	_amount : 1*Math.pow(10,18).toString(),
			// 	_payType : 'BNB'
			// })
			
			// // 创建合案件
			// createCase(0,1,100,'案件详情',['11','22','33']).then((res)=>{
			// 	console.log('创建合案件',+res)
			// })
			
			
			
			// // user购买盲盒
			// user.buyBoxs(50,1).then((res)=>{
			// 	console.log('user购买盲盒',+res)
			// })
			
			
			// user购买nft
			// user.buyNfts(2,1).then((res)=>{
			// 	console.log('user购买nft',+res)
			// })
			
			// user购买SHEILD代币
			// user.sheldSwap(2,1).then((res)=>{
			// 	console.log('user购买SHEILD代币',+res)
			// })
			
			// user特工币兑换
			// user.swap(0,1000).then((res)=>{
			// 	console.log('user特工币兑换',+res)
			// })
			
			
			
			
			
			// nft
			
			// NFT 图片URL
			
			// nft.tokenURI(1).then((res)=>{
			// 	console.log('tokenURI=',res)
			// })
			
			// nft余额
			// nft.balanceOf().then((res)=>{
			// 	console.log('nft余额='+res)
			// })
			// // nft查询是否授权
			// nft.isApprovedForAll().then((res)=>{
			// 	console.log('##nft查询是否授权=',res)
			// })
			
			// // USDT授权
			// nft.approve().then((res)=>{
			// 	console.log('授权=',res)s
			// })
			
			// nft授权
			// nft.setApprovalForAll().then((res)=>{
			// 	console.log('nft授权=',res)
			// })
			
			
			
			
			
			
			
			
			// //代币名称
			// name().then((res)=>{
			// 	console.log('代币名称='+res)
			// })
			
			// //代币精度
			// decimals().then((res)=>{
			// 	console.log('代币精度='+res)
			// })
			
			// // 代币余额
			// balanceOf().then((res)=>{
			// 	console.log('代币余额='+res)
			// })
			
			// // 代币符号
			// symbol().then((res)=>{
			// 	console.log('代币符号='+res)
			// })
			
			// // 代币总和
			// totalSupply().then((res)=>{
			// 	console.log('代币总和='+res)
			// })
			
			
			// // 授权额度
			// allowance().then((res)=>{
			// 	console.log('授权额度='+res)
			// })
			
			// // 授权
			// approve().then((res)=>{
			// 	console.log('授权='+res)
			// })
			
			
			
			
			
			
			// //特工币名称
			// agent.name().then((res)=>{
			// 	console.log('特工币名称='+res)
			// })
			
			// //特工币精度
			// agent.decimals().then((res)=>{
			// 	console.log('特工币精度='+res)
			// })
			
			// // 特工币余额
			// agent.balanceOf().then((res)=>{
			// 	console.log('特工币余额='+res)
			// })
			
			// // 特工币符号
			// agent.symbol().then((res)=>{
			// 	console.log('特工币符号='+res)
			// })
			
			// // 特工币总和
			// agent.totalSupply().then((res)=>{
			// 	console.log('特工币总和='+res)
			// })
			
			// // 特工币授权额度
			// agent.allowance().then((res)=>{
			// 	console.log('特工币授权额度='+res)
			// })
			
			// // 特工币授权
			// agent.allowance().then((res)=>{
			// 	console.log('特工币授权='+res)
			// })
			
			
			
			
			// onBeforeMount(()=>{
			// 	createWeb3().then((res)=>{
			// 		console.log('@@@@@@@@@@@@@',res)
			// 	})
			// })
			// onMounted(()=>{
			// 	getBalance().then((res)=>{
					
			// 		console.log("代币余额==",res);
			// 	})
			// })
			
			
			return{
				
			}
		}
		
		
	}
</script>

<style>
	@import url('assets/css/reset.css');
</style>

